<template>
  <vx-card v-if="calculator" :title="calculator.name">
    <form data-vv-scope="personal-loan-data" ref="form" @submit.prevent class="grid gap-8 md:grid-cols-2 grid-cols-1">
      <vx-card
        v-if="calculator"
        :beforeChange="validatePersonalCondition"
      >
        <div>
          <div class="vx-col w-2/5">
            <label :for="`rate`" class="w-full select-large">Porcentaje mínimo de prima</label>
            <vs-input
              icon-pack="feather"
              icon="icon-percent"
              icon-after="true"
              id="premium-rate"
              name="premium-rate"
              v-model="calculator.settings.premium_settings.min_percentage"
              class="w-full"
              v-validate="'required|decimal:2|min_value:1|max_value:100'"
              autocomplete="off"
            />
            <span class="text-danger">{{ errors.first(`personal-loan-data.premium-rate`) }}</span>
            <br />
          </div>

          <div class="vx-col w-2/5">
            <label for="rate" class="w-full select-large">Tasa</label>
            <vs-input
              icon-pack="feather"
              icon="icon-percent"
              icon-after="true"
              id="rate"
              name="rate"
              v-model="calculator.settings.rate"
              class="w-full"
              v-validate="'required|decimal:2|min_value:1|max_value:100'"
              autocomplete="off"
            />
            <span class="text-danger">{{ errors.first(`personal-loan-data.rate`) }}</span>
            <br />
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label :for="`termDataInterval`">Plazos disponibles</label>
            </div>

            <div class="vx-col md:w-1/5 w-full md:pr-0">
              <vs-select
                id="term"
                name="term"
                v-model="calculator.settings.term_data.type"
                v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="false" value="months" :text="'Meses'" class="w-full" />
                <vs-select-item :key="true" value="years" :text="'Años'" class="w-full" />
              </vs-select>
              <span class="text-danger">{{ errors.first(`personal-loan-data.term`) }}</span>
              <br />
            </div>

            <div class="vx-col md:w-2/5 w-full md:pr-0">
              <vs-input
                id="termDataInterval"
                name="term-data-interval"
                v-model="calculator.settings.term_data.intervals"
                class="w-full"
                v-validate="'required|termDataInterval'"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first(`personal-loan-data.term-data-interval`) }}</span>
              <br />
            </div>
          </div>

          <div class="vx-row">
            <vs-divider class="vx-col w-full mt-5" position="center" color="dark">Configuración monto de préstamo</vs-divider>
            <div class="vx-col w-full pb-12">
              <div class="vx-col w-full">
                <div class="vx-col w-2/5">
                  <label :for="`min-loan-amount`" class="w-full select-large">Monto mínimo de préstamo</label>
                  <vs-input
                    id="min-loan-amount"
                    name="min-loan-amount"
                    v-model="calculator.settings.loan_amount_settings.min.value"
                    class="w-full"
                    v-validate="'required|decimal:2|min_value:1'"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ errors.first(`personal-loan-data.min-loan-amount`) }}</span>
                  <br />
                </div>
              </div>

              <template v-if="calculator.settings.loan_amount_settings.max.length > 0">
                <div v-for="(condition, conditionIndex) in calculator.settings.loan_amount_settings.max" class="vx-col justify-end w-full">
                  <div class="vx-row">
                    <div class="vx-col w-11/12">
                      <div class="vx-row">
                        <div class="vx-col w-1/3">
                          <label :for="`value-${conditionIndex}`">Salario base</label>
                          <vs-input
                            :id="`income-${conditionIndex}`"
                            :name="`income-${conditionIndex}`"
                            v-model="condition.value"
                            class="w-full"
                            v-validate="
                            `required|decimal:2|min_value:0`
                          "
                            autocomplete="off"
                          />
                          <span class="text-danger">{{ errors.first(`personal-loan-data.income-${conditionIndex}`) }}</span>
                          <br />
                        </div>

                        <div class="vx-col w-1/3">
                          <label for="category" class="w-full select-large">Monto mínimo</label>
                          <vs-input
                            :id="`income-min-${conditionIndex}`"
                            :name="`income-min-${conditionIndex}`"
                            v-model="condition.conditions.income.min"
                            class="w-full"
                            v-validate="`required|decimal:2|min_value:0|max_value:${condition.conditions['income'].max}`"
                            autocomplete="off"
                          />
                          <span class="text-danger">{{ errors.first(`personal-loan-data.income-min-${conditionIndex}`) }}</span>
                          <br/>
                        </div>

                        <div class="vx-col w-1/3">
                          <label for="category" class="w-full select-large">Monto máximo</label>
                          <vs-input
                            :id="`income-max-${conditionIndex}`"
                            :name="`income-max-${conditionIndex}`"
                            v-model="condition.conditions.income.max"
                            class="w-full"
                            v-validate="`required|decimal:2|min_value:${condition.conditions['income'].min}`"
                            autocomplete="off"
                          />
                          <span class="text-danger">{{ errors.first(`personal-loan-data.income-max-${conditionIndex}`) }}</span>
                          <br/>
                        </div>

                      </div>
                    </div>

                    <div
                      v-if="calculator.settings.loan_amount_settings.max.length > 1"
                      class="vx-col w-1/12 flex justify-start self-center pl-0"
                    >
                      <vs-button
                        size="medium"
                        color="danger"
                        type="border"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click="removeLoanAmountMaxSetting(calculator.settings.loan_amount_settings.max, conditionIndex)"
                      ></vs-button>
                    </div>
                  </div>
                </div>
              </template>

              <div class="vx-col flex justify-end">
                <vs-button
                  size="small"
                  color="success"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="addLoanAmountMaxC(calculator.settings.loan_amount_settings.max, 'income')"
                >
                  Agregar condición
                </vs-button>
              </div>
            </div>
          </div>

        </div>
      </vx-card>
    </form>

    <div class="flex justify-end mt-5">
      <vs-button
        v-if="$can('update', 'calculator')"
        size="small"
        icon-pack="feather"
        icon="icon-save"
        @click="updateCalculator()"
        class="ml-4"
      >
        Guardar cambios
      </vs-button>
    </div>
  </vx-card>
</template>

<script>
  import vSelect from "vue-select";
  import { cloneDeep } from "lodash";
  import { Validator } from "vee-validate";

  import CoreClient from "@/utils/coreClient";
  import Notifier from "@/utils/notification";
  import { termDataInterval } from "@/utils/customValidations";

  Validator.extend("termDataInterval", termDataInterval);

  const calculatorsClient = new CoreClient("calculators");
  let notifier = new Notifier();

  Validator.localize({
    en: {
      messages: {
        required: "Este campo es requerido",
        decimal: (_, decimals) => `El campo debe ser numérico y puede contener hasta ${decimals} puntos decimales`,
        min_value: (_, min) => `El campo no debe ser menor a: ${min}`,
        max_value: (_, max) => `El campo no debe ser mayor a: ${max}`,
      },
    },
  });

  export default {
    name: "PersonalCalculator",

    components: {
      "v-select": vSelect,
    },

    data() {
      return {
        calculator: null,
      };
    },

    mounted() {
      this.$vs.loading();

      this.getCalculator(false).then(() => {
        this.$vs.loading.close();
      });
    },

    methods: {
      addLoanAmountMaxC(max, condition) {
        max.push({
          value: 0,
          conditions: {
            [condition]: {
              min: 0,
              max: null,
            },
          },
        });
      },

      buildData(settings) {
        settings.term_data.intervals = [
          ...new Set(settings.term_data.intervals.split(",").map(Number)),
        ].sort((a, b) => a - b);

        settings["rates_by_currency"] = {
          "HNL": [
            {
              rate: Number(settings.rate),
              conditions: {}
            }
          ]
        }

        return {
          name: this.calculator.name,
          settings: {
            term_data: settings.term_data,
            premium_settings: settings.premium_settings,
            loan_amount_settings: settings.loan_amount_settings,
            rates_by_currency: settings.rates_by_currency,
          }
        };
      },

      async updateCalculator() {
        this.validatePersonalCondition()
            .then(async (resp) => {
              if (resp === true) {
                this.$vs.loading();

                await calculatorsClient.updateCalculator({
                  pathParameters: { id: this.calculator.id },
                  data: this.buildData(cloneDeep(this.calculator.settings)),
                })
                  .then(() => {
                    this.$vs.loading.close();
                    notifier.notification("created");
                    this.getCalculator();
                  })
                  .catch(() => {
                    this.$vs.loading.close();
                    notifier.alertMessage("error");
                  });
              }
            })
            .catch(() => {
              return;
            });
      },

      async getCalculator(showLoading = true) {
        if (showLoading) {
          this.$vs.loading();
        }

        await calculatorsClient.readCalculator({ pathParameters: { id: this.$route.params.id } } )
          .then((response) => {
            if (showLoading) {
              this.$vs.loading.close();
            }

            this.calculator = response.data;

            this.prepareData();
          })
          .catch(() => {
            if (showLoading) {
              this.$vs.loading.close();
            }
            notifier.notification("error");
          });
      },

      removeLoanAmountMaxSetting(max, index) {
        max.splice(index, 1);
      },

      prepareData() {
        this.calculator.settings.term_data.intervals = this.calculator.settings.term_data.intervals.join(", ");

        let ratesByCurrency = [];

        for (const currency in this.calculator.settings.rates_by_currency) {
          ratesByCurrency.push({
            currency: currency,
            rates: this.calculator.settings.rates_by_currency[currency],
          });
        }

        this.$set(this.calculator.settings, "rate", ratesByCurrency[0].rates[0].rate);
      },
      
      validatePersonalCondition() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll("personal-loan-data").then((result) => {
            if (result) {
              resolve(true);
            } else {
              reject("Please fix invalid fields");
            }
          });
        });
      },
    }
  }
</script>

<style scoped>

</style>